<script setup lang="ts">
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import type { Notification } from '~~/types/Notification'
import { notificationStore } from '~/stores/notificationStore'

const { item } = defineProps<{
  item: Notification
}>()

const notification = notificationStore()

const closeTimeout = ref<NodeJS.Timeout | null>(null)

const close = async (id: Notification['id']) => {
  notification.close(id)
}

onMounted(() => {
  closeTimeout.value = setTimeout(() => {
    close(item.id)
    closeTimeout.value = null
  }, 5000)
})

onBeforeUnmount(() => {
  if (closeTimeout.value) {
    clearTimeout(closeTimeout.value)
    closeTimeout.value = null
  }
})

const iconTypes = {
  success: { component: CheckCircleIcon, class: 'text-green-400' },
  error: { component: ExclamationCircleIcon, class: 'text-red-400' },
  warning: { component: ExclamationTriangleIcon, class: 'text-orange-400' },
  info: { component: InformationCircleIcon, class: 'text-blue-400' },
}

const componentComp = computed(() => iconTypes[item.type].component)
const componentClasses = computed(() => iconTypes[item.type].class)
</script>

<template>
  <div
    v-show="item"
    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="shrink-0 pt-1">
          <component :is="componentComp" class="size-6" :class="componentClasses" aria-hidden="true" />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-slate-900">
            {{ item.title }}
          </p>
          <p v-if="item.message" class="mt-1 text-sm text-slate-500">
            {{ item.message }}
          </p>
        </div>
        <div class="ml-4 flex shrink-0">
          <button
            type="button"
            class="inline-flex rounded-md bg-white text-slate-400 transition-all hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            @click="close(item.id)"
          >
            <span class="sr-only">Close</span>
            <XMarkIcon class="size-5 text-base text-slate-400" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
